.background {
    background: linear-gradient(45deg, rgb(44, 43, 43) 10.19%, #05fa77 49.99%, #2195fb 78.51%);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

.content-wrapper {
    position: relative;
    z-index: 1;
    overflow: auto;
    height: 100vh; /* Adjust height as necessary */
}

@keyframes gradient {
    0% {
        background-position: 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 50%;
    }
}
