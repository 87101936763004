@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

.dashboard-container {
  display: flex;
  justify-content: center;
  color: #f5e9e9;
  align-items: center;
  font-family: 'Inter', sans-serif;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  padding: 20px;
  text-align: center;
  font-family: 'Inter', sans-serif;
}

#data-store {
  width: 100%;
  height: 100%;
  background-color: rgba(14, 13, 13, 0.69);
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
  margin-right: 10px;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
}

.upload {
  margin-top: 20px;
  margin-left: -20px;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  font-family: 'Inter', sans-serif;
}

.btn {
  font-size: 1rem;
  font-weight: 700;
  padding: 0.5em 1em;
  color: #f6f3f3;
  background-color: rgba(14, 13, 13, 0.69);
  border-radius: 5px;
  transition: all 0.3s;
  font-family: 'Inter', sans-serif;
}

.btn:hover {
  transform: translateY(-10px);
  background-color: rgba(115, 138, 162, 0.6);
  color: rgb(14, 14, 14);
}
