@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

.navbar,
.sidebar-links li a {
    font-family: 'Inter', sans-serif;
}

.navbar {
    position: relative;
    display: inherit;
}

.navbar-toggle {
    background: none;
    border: none;
    position: fixed;
    top: 0;
    right: -95%;
    z-index: 2;
    margin-top: 15px;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px 0;
    background-color: #0a0a0a;
    transition: transform 0.3s, opacity 0.3s;
}

.sidebar {
    position: fixed;
    top: 0;
    right: -250px;
    height: 100%;
    width: 250px;
    background-color: rgba(11, 130, 255, 0.23);
    transition: right 0.3s;
    z-index: 1;
}

.sidebar.active {
    right: 0;
}

.sidebar-links {
    list-style-type: none;
    padding: 0;
    margin-top: 50px;
}

.sidebar-links li {
    padding: 25px;
    display: block;
    border-bottom: 1px solid #ffffff;
}

.sidebar-links li a {
    color: #100f0f;
    text-decoration: none;
    display: block;
}

.sidebar-links li a:hover {
    color: white;
}

.logo-container {
    position: relative;
    display: inherit;
    margin-right: auto;
}

.logo {
    width: 120px;
    height: 120px;
    margin-left: 20px;
}
