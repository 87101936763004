@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

.upload-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    background-color: rgba(11, 130, 255, 0.38);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: 'Inter', sans-serif;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
    text-align: center;
}

.custom-file-upload {
    max-width: 100%;
    margin: 20px auto;
    padding: 20px;
    background-color: rgba(11, 130, 255, 0.38);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-family: 'Inter', sans-serif;
    box-sizing: border-box;
}

.upload-section-container label,
.upload-section-container button {
    display: block;
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    margin: 10px auto;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 200px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
}

.upload-section-container label:hover,
.upload-section-container button:hover {
    background-color: #45a049;
}

.upload-section-container p {
    color: red;
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.popup button {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.popup p {
    margin-top: 10px;
    color: #333;
    font-size: 16px;
}
