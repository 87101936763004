@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

.container,
form,
input,
button,
.toggle-button,
.error-message,
p,
h2,
.user-icon,
.user-icon span {
  font-family: 'Inter', sans-serif;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #ffffff;
}

.form-container {
  background-color: rgba(14, 13, 13, 0.69);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 350px;
  margin-top: 10px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

input,
button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button {
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}

.toggle-button {
  background-color: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
}

.toggle-button:hover {
  text-decoration: underline;
}

.error-message {
  color: red;
}

p {
  margin-top: 15px;
  text-align: center;
}

h2 {
  text-align: center;
}

.user-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #007bff;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.user-icon span {
  color: #ffffff;
  margin-bottom: 5px;
  text-align: center;
}
